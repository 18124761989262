import styled from 'styled-components';
import { Color } from '../../../constants/Color.enum';
import { LoadingIcon } from '../../icons/Loading/Loading';

interface LoaderProps {
    isPending: boolean;
    gray?: boolean;
}

export const Loader = ({ isPending, gray }: LoaderProps) => (
    <LoadingIconIconWrapper data-test="renderer-loader" isPending={isPending} gray={gray}>
        {isPending && <LoadingIcon />}
    </LoadingIconIconWrapper>
);

const LoadingIconIconWrapper = styled.div<LoaderProps>`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: ${({ isPending }) => (isPending ? 'all' : 'none')};
    display: flex;
    background-color: ${({ gray }) => (gray ? 'rgb(245, 243, 240, 0.8)' : 'rgba(255, 255, 255, 0.8)')};
    opacity: ${({ isPending }) => (isPending ? '1' : '0')};
    justify-content: center;
    align-items: center;
    transition: ${({ isPending }) => (isPending ? 'opacity linear 0.4s 0.2s' : 'opacity linear 0.2s 0s')};
    color: ${Color.Primary};
    top: 0;
    left: 0;
`;
