import { useState } from 'react';
import { HeaderIconStyled } from '../Header/Header.styled';
import { HeaderPopup } from '../Portal/HeaderPopup/HeaderPopup';
import { Tr, useTr } from '../Translations/Tr';
import styled from 'styled-components';
import { Color, SubtitleS, TextXS } from '@formify/frontend-components';
import { useCurrency } from './CurrencyProvider';

const LangItem = styled.div<{
    selected: boolean;
}>`
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px;
    padding: 8px;
    pointer-events: ${(props) => (props.selected ? 'none' : 'auto')};
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

interface CurrencyHeaderProps {
    transparent?: boolean;
}

export const CurrencyHeader = ({ transparent }: CurrencyHeaderProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { currentCurrency, setCurrency, availableCurrency } = useCurrency();
    const selectCurrencyTitle = useTr('selectCurrencyTitle', 'SELECT CURRENCY');
    const currencySelectedInfo = useTr('currencySelectedInfo', 'Selected');

    if (availableCurrency.length <= 1) {
        return null;
    }

    return (
        <div style={{ position: 'relative' }}>
            <HeaderIconStyled>
                <div>
                    <SubtitleS
                        onClick={() => {
                            setIsOpen((state) => !state);
                        }}
                        color={transparent ? Color.Surface : undefined}
                    >
                        {currentCurrency.toUpperCase()}
                    </SubtitleS>
                </div>
            </HeaderIconStyled>

            {isOpen && (
                <HeaderPopup title={selectCurrencyTitle} onClose={() => setIsOpen(false)} right="106px" width="340px">
                    {availableCurrency.map((currencyCode) => (
                        <LangItem
                            key={currencyCode}
                            onClick={() => {
                                setCurrency(currencyCode);
                                setIsOpen(false);
                            }}
                            selected={currentCurrency === currencyCode}
                        >
                            <TextXS fontWeight={400}>
                                <Tr labelKey={`fullCurrencyName.${currencyCode}`} defaultValue={currencyCode} />
                            </TextXS>
                            <TextXS>{currentCurrency === currencyCode ? currencySelectedInfo : ''}</TextXS>
                        </LangItem>
                    ))}
                </HeaderPopup>
            )}
        </div>
    );
};
