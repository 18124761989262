import React, { FC } from 'react';
import { BasketIcon } from '../../icons/Basket/Basket';
import { BadgeIcon } from '../../icons/Badge/Badge';
import { useSelector } from 'react-redux';
import { getCartItemsCount } from '../../../store/project/selectors';

const styleValue = { right: '-6px' };

export const ProductCountBasket: FC = () => {
    const cartItemsCount = useSelector(getCartItemsCount);

    return (
        <div className="flex-col flex items-center">
            <div className="relative" data-test-id="basket_icon">
                <BasketIcon />
                <div className="absolute -top-1" style={styleValue}>
                    <BadgeIcon value={cartItemsCount} />
                </div>
            </div>
        </div>
    );
};
