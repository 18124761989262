import React, { FC } from 'react';
import { ClearIcon } from '../icons/Clear/Clear';
import { InstagramIcon } from '../icons/Instagram/Instagram';
// import { PinterestIcon } from '../icons/Pinterest/Pinterest';
import { LinkXS } from '@formify/frontend-components';
import Link from 'next/link';
import { TextS } from '@formify/frontend-components';
import { useSelector } from 'react-redux';
import {
    ContactWrapper,
    IconWrapper,
    ItemTitle,
    MenuContainerStyled,
    MenuItems,
    SocialMediaWrapper,
    Wrapper,
} from './Menu.styled';
import { getStaticPages } from '../../store/layout/selectors';
import { privacyPolicyRoute, RouteName, termsAndConditionsRoute } from '../../constants/router';
import { StaticPageSimple } from '../../services/staticPages/staticPages';
import snakeCase from 'lodash/snakeCase';
import { useHomePageUrl } from '../../hooks/useHomePageUrl';
import { useLanguage } from '../Translations/LanguageProvider';
import { useEnv } from '../../hooks/useEnv';

interface MenuProps {
    isOpen?: boolean;
    onClose: () => void;
}

export const Menu: FC<MenuProps> = ({ isOpen = false, onClose }) => {
    const { currentLanguage } = useLanguage();
    const cookiePolicyRoute = useEnv('COOKIE_POLICY_URL') ?? '';

    const pages = useSelector(getStaticPages).filter((page: StaticPageSimple) => {
        const path = `${RouteName.Static}?lang=${currentLanguage}&slug=${page.slug}`;

        return path !== termsAndConditionsRoute && path !== privacyPolicyRoute && path !== cookiePolicyRoute;
    });

    const homepage = useHomePageUrl();

    const handleItemClick = (pathname: string) => {
        if (location.pathname === pathname) {
            onClose();
        }
    };

    return (
        <MenuContainerStyled data-test="main_menu" isOpen={isOpen}>
            <Wrapper isOpen={isOpen}>
                <IconWrapper onClick={onClose}>
                    <ClearIcon />
                </IconWrapper>
                <MenuItems>
                    <div data-test-id="home-page-link" onClick={() => handleItemClick(homepage)}>
                        <Link href={homepage} prefetch={isOpen} passHref legacyBehavior>
                            <ItemTitle>Home</ItemTitle>
                        </Link>
                    </div>
                    <div
                        data-test-id="configurator-page-link"
                        onClick={() => {
                            // TODO reset project in other way or delete Menu file
                            // resetProject();
                            if (location.pathname.indexOf('/wardrobe') !== -1) {
                                onClose();
                            }
                        }}
                    >
                        <Link href="/wardrobe" prefetch={isOpen} passHref legacyBehavior>
                            <ItemTitle>Start design</ItemTitle>
                        </Link>
                    </div>
                    {pages.map((page, index) => {
                        const slug = page.slug[currentLanguage];
                        const name = page.name[currentLanguage];

                        if (!name || !slug) {
                            return <React.Fragment key={index} />;
                        }

                        const path = `${RouteName.Static}?lang=${currentLanguage}&slug=${slug}`;

                        return (
                            <div data-test-id={snakeCase(slug)} key={index} onClick={() => handleItemClick(path)}>
                                <Link href={path} passHref prefetch={isOpen} legacyBehavior>
                                    <ItemTitle>{name}</ItemTitle>
                                </Link>
                            </div>
                        );
                    })}
                </MenuItems>
                <ContactWrapper>
                    <LinkXS className="mb-12">Contact</LinkXS>
                    <TextS>Formify BV</TextS>
                    <TextS>Wibautstraat 131D</TextS>
                    <TextS>1091GL Amsterdam</TextS>
                    <TextS>The Netherlands</TextS>
                    <TextS className="mt-24">+31(0)20&nbsp;225&nbsp;94&nbsp;49</TextS>
                    <TextS className="mb-40">
                        <a href="mailto:info@formify.com">info@formify.com</a>
                    </TextS>
                    <LinkXS>Follow us</LinkXS>
                    <SocialMediaWrapper>
                        <a href="https://www.instagram.com/formify.closets" target="_blank" rel="noreferrer">
                            <InstagramIcon />
                        </a>
                        {/* <a href="https://www.pinterest.com/" target="_blank" rel="noreferrer">
                            <PinterestIcon />
                        </a> */}
                    </SocialMediaWrapper>
                    <TextS>
                        <a href={termsAndConditionsRoute} className="underline">
                            Terms & Conditions
                        </a>
                    </TextS>
                    <TextS>
                        <a href={privacyPolicyRoute} className="underline">
                            Privacy policy
                        </a>
                    </TextS>
                    <TextS>
                        <a
                            href={cookiePolicyRoute}
                            target={cookiePolicyRoute.startsWith('http') ? '_blank' : undefined}
                            className="underline"
                            rel="noreferrer"
                        >
                            Cookie policy
                        </a>
                    </TextS>
                </ContactWrapper>
            </Wrapper>
        </MenuContainerStyled>
    );
};
