import styled from 'styled-components';
import Breakpoint from '../../constants/Breakpoint.enum';
import { Color } from '../../constants/Color.enum';

interface MenuProps {
    isOpen: boolean;
}

export const MenuContainerStyled = styled.div<MenuProps>`
    background-color: ${Color.Background};
    width: 100vw;
    z-index: 1000;
    position: fixed;
    overflow: auto;
    height: 100vh;
    transform: ${({ isOpen }) => (isOpen ? 'translateY(0%)' : 'translateY(-100%)')};
    transition: transform 0.5s;
    transition-delay: ${({ isOpen }) => (isOpen ? 0 : '0.5s')};
`;

export const IconWrapper = styled.div`
    margin: 0 6px;
    padding: 20px;
    cursor: pointer;
    position: fixed;
    left: 0;
    top: 0;
`;

export const MenuItems = styled.div`
    display: flex;
    flex-direction: column;

    & > :not(:last-child) {
        margin-bottom: 60px;
    }

    @media (max-width: ${parseInt(Breakpoint.Tablet)}px) {
        & > :not(:last-child) {
            margin-bottom: 42px;
        }
    }

    @media (max-width: ${parseInt(Breakpoint.Mobile)}px) {
        & > :not(:last-child) {
            margin-bottom: 12px;
        }
    }

    @media (max-width: ${parseInt(Breakpoint.Desktop)}px) {
        margin-bottom: 60px;
    }

    @media (min-width: ${Breakpoint.Desktop}) {
        margin-right: 40px;
    }
`;

export const ContactWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SocialMediaWrapper = styled.div`
    margin-top: 16px;
    margin-bottom: 40px;
    display: flex;

    & > :not(:last-child) {
        margin-right: 26px;
    }
`;

export const Wrapper = styled.div<MenuProps>`
    margin: 80px 0;
    padding: 0 60px;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    transition: transform 0.5s, opacity 0.5s;
    transition-delay: ${({ isOpen }) => (isOpen ? '0.5s' : 0)};
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: ${Breakpoint.Tablet}) {
        padding: 0 140px;
        margin: 140px 0;
    }

    @media (min-width: ${Breakpoint.Desktop}) {
        flex-direction: row;
        margin: 160px 0;
        padding: 0 177px 0 120px;
    }

    @media (min-width: ${Breakpoint.LargeDesktop}) {
        margin: 200px 0;
        padding: 0 350px 0 180px;
    }

    @media (min-width: ${Breakpoint.ExtraLargeDesktop}) {
        padding: 0 544px 0 466px;
    }
`;

export const ItemTitle = styled.a`
    max-height: 50px;
    width: fit-content;
    color: ${Color.Primary};
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 3.125rem;
    cursor: pointer;
    letter-spacing: 0.063rem;
    display: inline-block;
    position: relative;

    @media (min-width: ${Breakpoint.Tablet}) {
        font-size: 2.625rem;
    }

    @media (min-width: ${Breakpoint.LargeDesktop}) {
        font-size: 3rem;
    }
    @media (min-width: ${Breakpoint.ExtraLargeDesktop}) {
        font-size: 3rem;
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: -5px;
        left: 0;
        background-color: ${Color.Primary};
        transform-origin: bottom left;
        transition: transform 0.25s ease-out;
    }

    &:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
`;
