import React, { FC } from 'react';

export const InstagramIcon: FC = () => (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.83656 0H11.4889C13.7482 0 15.5863 1.84389 15.5862 4.11022V10.7838C15.5862 13.0501 13.7482 14.894 11.4889 14.894H4.83656C2.57744 14.894 0.739502 13.0502 0.739502 10.7838V4.11022C0.739502 1.84389 2.57744 0 4.83656 0ZM11.489 13.5718C13.0219 13.5718 14.269 12.3208 14.269 10.7832H14.2689V4.1099C14.2689 2.57231 13.0218 1.32129 11.4889 1.32129H4.83655C3.30378 1.32129 2.05676 2.57231 2.05676 4.1099V10.7832C2.05676 12.3208 3.30378 13.5719 4.83655 13.5718H11.489Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.3374 7.44638C4.3374 5.33062 6.05354 3.60938 8.16301 3.60938C10.2725 3.60938 11.9886 5.33062 11.9886 7.44638C11.9886 9.56204 10.2725 11.2832 8.16301 11.2832C6.05354 11.2832 4.3374 9.56204 4.3374 7.44638ZM5.65479 7.44638C5.65479 8.83349 6.78008 9.96191 8.16312 9.96191C9.54617 9.96191 10.6715 8.83349 10.6715 7.44638C10.6715 6.05918 9.54625 4.93066 8.16312 4.93066C6.77999 4.93066 5.65479 6.05918 5.65479 7.44638Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1508 2.48828C11.897 2.48828 11.6477 2.59135 11.4685 2.77193C11.2884 2.95164 11.1848 3.20181 11.1848 3.45728C11.1848 3.71194 11.2884 3.96203 11.4685 4.14262C11.6476 4.32232 11.897 4.42627 12.1508 4.42627C12.4055 4.42627 12.654 4.32232 12.834 4.14262C13.0141 3.96203 13.1168 3.71186 13.1168 3.45728C13.1168 3.20181 13.0141 2.95164 12.834 2.77193C12.6549 2.59135 12.4055 2.48828 12.1508 2.48828Z"
            fill="currentColor"
        />
    </svg>
);
