import React, { FC } from 'react';

export const BasketIcon: FC = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.37211 2.34895C8.73175 2.55445 8.8567 3.01259 8.65119 3.37223L7.29239 5.75013H11.0426L9.38971 3.43606C9.14895 3.099 9.22702 2.63058 9.56408 2.38983C9.90114 2.14907 10.3695 2.22714 10.6103 2.5642L12.886 5.75013H16C16.2291 5.75013 16.4457 5.85486 16.5879 6.03448C16.7302 6.21409 16.7825 6.44888 16.7301 6.67191L14.7301 15.1719C14.6504 15.5107 14.348 15.7501 14 15.7501H4.50001C4.1673 15.7501 3.87436 15.5309 3.78048 15.2118L1.28048 6.71175C1.21379 6.48499 1.25789 6.24006 1.3995 6.05081C1.5411 5.86155 1.76364 5.75013 2.00001 5.75013H5.56476L7.34882 2.62802C7.55433 2.26839 8.01247 2.14344 8.37211 2.34895ZM3.00236 7.25013L5.06119 14.2501H13.406L15.0531 7.25013H3.00236Z"
            fill="currentColor"
        />
    </svg>
);
