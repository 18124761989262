import styled from 'styled-components';
import { Color } from '../../../constants/Color.enum';

export const BadgeWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Color.Primary};
    width: 16px;
    height: 16px;
    color: ${Color.Surface};
    border-radius: 50%;
    font-size: 10px;
    line-height: initial;
    z-index: 1;
    margin-left: -10px;
    font-weight: 500;
    border: 1px solid ${Color.Surface};
`;
