import { Breakpoint, TextS, ClearIcon, Button } from '@formify/frontend-components';
import styled from 'styled-components';
import { Portal } from '../Portal';

const PopupBackground = styled.div`
    position: fixed;
    z-index: 998;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    background: rgb(0 0 0 / 25%);

    @media (min-width: ${Breakpoint.Tablet}) {
        display: none;
    }
`;

const PopupContainer = styled.div<{
    width?: string;
    right?: string;
}>`
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    right: 0;
    padding: 24px;
    background: #fff;

    @media (min-width: ${Breakpoint.Tablet}) {
        position: absolute;
        right: ${(props) => props.right || '54px'};
        top: 54px;
        width: ${(props) => props.width || '420px'};
    }

    :after {
        position: absolute;
        content: '';
        display: none;
        top: -16px;
        right: 15px;
        border: 12px solid transparent;
        border-top: 0px solid transparent;
        border-bottom: 16px solid #fff;

        @media (min-width: ${Breakpoint.Tablet}) {
            display: block;
        }
    }
`;

interface HeaderPopupProps {
    title: string;
    onClose: () => void;
    children: React.ReactNode;
    width?: string;
    right?: string;
}

export const HeaderPopup = ({ onClose, children, title, right, width }: HeaderPopupProps) => (
    <Portal>
        <PopupContainer right={right} width={width}>
            <div className="mb-16 flex justify-between w-full items-center">
                <TextS>{title}</TextS>
                <div>
                    <Button variant="outlined-white" icon={<ClearIcon />} onClick={onClose}></Button>
                </div>
            </div>
            {children}
        </PopupContainer>
        <PopupBackground onClick={onClose} />
    </Portal>
);
