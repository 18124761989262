import { useEffect, useState } from 'react';
import { getPublicEnv } from '../services/env/env';
import { RouteName } from '../constants/router';

export function useHomePageUrl() {
    const [homePageUrl, setHomePageUrl] = useState<string>(RouteName.Home);

    useEffect(() => {
        getPublicEnv('HOME_PAGE_URL').then((env) => {
            if (env) {
                setHomePageUrl(env);
            }
        });
    }, []);

    return homePageUrl;
}
