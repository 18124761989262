import React, { FC, useEffect, useState } from 'react';
import { useChannel } from '../../Channel/ChannelProvider';
import styled from 'styled-components';

interface LogoIconProps {
    width?: number;
}

const LogoWrapperStyled = styled.div<{
    maxWidth: number;
}>`
    display: flex;
    height: 100%;
    max-width: ${({ maxWidth }) => `${maxWidth}px`};

    svg {
        width: 100%;
        max-height: 100%;
    }
`;

export const LogoIcon: FC<LogoIconProps> = ({ width = 107 }) => {
    const { logo } = useChannel();
    const [svgContent, setSvgContent] = useState<string>('');

    useEffect(() => {
        if (logo) {
            fetch(logo)
                .then((response) => response.text())
                .then((text) => {
                    setSvgContent(text);
                });
        }
    }, [logo]);

    return (
        <LogoWrapperStyled
            maxWidth={width}
            dangerouslySetInnerHTML={{
                __html: svgContent,
            }}
        ></LogoWrapperStyled>
    );
};
