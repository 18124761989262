import React, { FC } from 'react';
import { BadgeWrapperStyled } from './Badge.styled';

export interface BadgeProps {
    value?: number;
}

export const BadgeIcon: FC<BadgeProps> = ({ value }) => {
    if (!value) {
        return null;
    }

    return <BadgeWrapperStyled>{value}</BadgeWrapperStyled>;
};
