import { createPortal } from 'react-dom';
import React, { useEffect, useMemo, useState } from 'react';

export const Portal: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);

        return () => setMounted(false);
    }, []);

    return mounted ? <PortalCreator>{children}</PortalCreator> : null;
};

const PortalCreator: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const container = useMemo(() => document.querySelector('#website-space-for-portals'), []);

    const element = useMemo(() => {
        if (!container) {
            return null;
        }

        const div = document.createElement('div');

        container.appendChild(div);

        return div;
    }, [container]);

    return element ? createPortal(children, element) : null;
};
