import { useState } from 'react';
import { HeaderIconStyled } from '../Header/Header.styled';
import { HeaderPopup } from '../Portal/HeaderPopup/HeaderPopup';
import { Tr, useTr } from '../Translations/Tr';
import styled from 'styled-components';
import { Color, SubtitleS, TextXS } from '@formify/frontend-components';
import { useMeasureUnit } from './MeasureUnitProvider';

const LangItem = styled.div<{
    selected: boolean;
}>`
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px;
    padding: 8px;
    pointer-events: ${(props) => (props.selected ? 'none' : 'auto')};
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

interface MeasureUnitHeaderProps {
    transparent?: boolean;
}

const measureUnitToUIUnit = {
    MM: 'M',
    DIN: 'IN',
};

export const MeasureUnitHeader = ({ transparent }: MeasureUnitHeaderProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { currentMeasureUnit, setMeasureUnit, availableMeasureUnit } = useMeasureUnit();
    const selectMeasureUnitTitle = useTr('selectMeasureUnitTitle', 'SELECT MEASURE');
    const measureUnitSelectedInfo = useTr('measureUnitSelectedInfo', 'Selected');

    if (availableMeasureUnit.length <= 1) {
        return null;
    }

    return (
        <div style={{ position: 'relative' }}>
            <HeaderIconStyled>
                <div>
                    <SubtitleS
                        onClick={() => {
                            setIsOpen((state) => !state);
                        }}
                        color={transparent ? Color.Surface : undefined}
                    >
                        {measureUnitToUIUnit[currentMeasureUnit].toUpperCase()}
                    </SubtitleS>
                </div>
            </HeaderIconStyled>

            {isOpen && (
                <HeaderPopup
                    title={selectMeasureUnitTitle}
                    onClose={() => setIsOpen(false)}
                    right="106px"
                    width="340px"
                >
                    {availableMeasureUnit.map((measureUnitCode) => (
                        <LangItem
                            key={measureUnitCode}
                            onClick={() => {
                                setMeasureUnit(measureUnitCode);
                                setIsOpen(false);
                            }}
                            selected={currentMeasureUnit === measureUnitCode}
                        >
                            <TextXS fontWeight={400}>
                                <Tr
                                    labelKey={`fullMeasureUnitName.${measureUnitCode}`}
                                    defaultValue={measureUnitToUIUnit[measureUnitCode]}
                                />
                            </TextXS>
                            <TextXS>{currentMeasureUnit === measureUnitCode ? measureUnitSelectedInfo : ''}</TextXS>
                        </LangItem>
                    ))}
                </HeaderPopup>
            )}
        </div>
    );
};
