import { useLayoutEffect, useState } from 'react';
import { HeaderIconStyled } from '../Header/Header.styled';
import { HeaderPopup } from '../Portal/HeaderPopup/HeaderPopup';
import { useLanguage } from '../Translations/LanguageProvider';
import { Tr, useTr } from '../Translations/Tr';
import styled from 'styled-components';
import { Color, SubtitleS, TextXS } from '@formify/frontend-components';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

const FlagContainer = styled.div`
    font-size: 24px;
    display: inline-block;
    height: 24px;
    line-height: 0;
    overflow: hidden;
    border-radius: 50%;
`;

const LangItem = styled.div<{
    selected: boolean;
}>`
    display: grid;
    grid-template-columns: 24px 1fr auto;
    gap: 8px;
    padding: 8px;
    pointer-events: ${(props) => (props.selected ? 'none' : 'auto')};
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

interface LangProps {
    transparent?: boolean;
}

export const Lang = ({ transparent }: LangProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { currentLanguage, setLanguage } = useLanguage();
    const selectLanguageTitle = useTr('selectLanguageTitle', 'SELECT LANGUAGE');
    const languageSelectedInfo = useTr('languageSelectedInfo', 'Selected');
    const service = useSelector((state: RootState) => state.dependency.services?.translation);

    const [supportedLanguages, setSupportedLanguages] = useState<
        {
            code: string;
            name: string;
        }[]
    >([]);

    useLayoutEffect(() => {
        if (!service) {
            return;
        }
        service.getAvailableLanguages().then((languages) => {
            setSupportedLanguages(languages);
        });
    }, [service]);

    if (supportedLanguages.length <= 1) {
        return null;
    }

    return (
        <div style={{ position: 'relative' }} data-test-id="profile_icon">
            <HeaderIconStyled>
                <div>
                    <SubtitleS
                        onClick={() => {
                            setIsOpen((state) => !state);
                        }}
                        color={transparent ? Color.Surface : undefined}
                    >
                        {currentLanguage.split('_')[0]?.toUpperCase()}
                    </SubtitleS>
                </div>
            </HeaderIconStyled>

            {isOpen && (
                <HeaderPopup title={selectLanguageTitle} onClose={() => setIsOpen(false)} right="106px" width="340px">
                    {supportedLanguages.map(({ code, name }) => (
                        <LangItem
                            key={code}
                            onClick={() => {
                                setLanguage(code);
                                setIsOpen(false);
                            }}
                            selected={currentLanguage === code}
                        >
                            <FlagContainer>
                                <span className={`fi fi-${code.split('_')[1]?.toLocaleLowerCase()} fis`}></span>
                            </FlagContainer>
                            <TextXS fontWeight={400}>
                                <Tr
                                    labelKey={`fullLanguageName.${code}`}
                                    defaultValue={name.substring(0, name.indexOf(' ('))}
                                />
                            </TextXS>
                            <TextXS>{currentLanguage === code ? languageSelectedInfo : ''}</TextXS>
                        </LangItem>
                    ))}
                </HeaderPopup>
            )}
        </div>
    );
};
